<template>
    <div class="home_section6_container">
        <div class="home_section6_wrap" :class="{ hidden: !isShowMore }">
            <h1 class="home_section6_title wow animate__animated animate__aplayzFadeInUp " data-wow-offset="30">
                <slot name="section6title">
                    연혁
                </slot>
            </h1>
            <slot name="section6subtitle">
                <h2 class="home_section6_subtitle1 wow animate__animated animate__aplayzFadeInUp " data-wow-offset="35">
                    어플레이즈는 현대자동차그룹 Spin-off 스타트업으로
                </h2>
                <h2 class="home_section6_subtitle2 wow animate__animated animate__aplayzFadeInUp " data-wow-offset="40">
                    AI 기반 공간 음악 시장 혁신을 선도하고 있습니다.
                </h2>
            </slot>

            <div class="home_section6_timeline wow animate__animated animate__aplayzFadeInUp " data-wow-offset="45">
                <ul class="w_100 flex_column time_line_wrap">
                    <li class="section6_line" :class="{ en: !isen }">
                        <!-- <div class="white_dot"></div>
                        <div class="white_dot white_dot2" :class="{ en: !isen }"></div>
                        <div class="white_dot white_dot3" :class="{ en: !isen }"></div>
                        <div class="white_dot white_dot4" :class="{ en: !isen }"></div> -->

                        <div class="white_line"></div>
                        <!-- ######### Box1  ######### -->
                        <div class="home_section6_box1" :class="{ en: !isen }">
                            <div class="white_dot"></div>
                            <h1 class="box_title">2024</h1>
                            <ul class="box1_history dp_flex flex_column">
                                <li class="dp_flex">
                                    <span class="box_month" :class="{ en: !isen }">
                                        <slot name="boxmonth1">
                                            6월
                                        </slot>
                                    </span>
                                    <p class="box_month_history" :class="{ en: !isen }">
                                        <slot name="boxmonthhistory1">
                                            팁스(TIPS) 창업사업화 및 해외마케팅 지원 사업 선정
                                        </slot>
                                    </p>
                                </li>
                                <li class="dp_flex">
                                    <span class="box_month" :class="{ en: !isen }">
                                        <slot name="boxmonth2">
                                            5월
                                        </slot>
                                    </span>
                                    <p class="box_month_history" :class="{ en: !isen }">
                                        <slot name="boxmonthhistory2">
                                            현대건설 x Seoul Startup 오픈 이노베이션 선정 <br />
                                            민관협력 오픈이노베이션 선정 - 한화호텔앤드리조트 <br />
                                            2024 서울창업허브 공덕 딥테크 허브배치 기업 선정 <br />
                                            넥스트페이먼츠 NDA 체결 및 PoC 상품 개발 협력
                                        </slot>
                                    </p>
                                </li>

                                <li class="dp_flex">
                                    <span class="box_month" :class="{ en: !isen }">
                                        <slot name="boxmonth3">
                                            1월
                                        </slot>
                                    </span>
                                    <p class="box_month_history" :class="{ en: !isen }">
                                        <slot name="boxmonthhistory3">
                                            CES 전시 참가
                                        </slot>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <!-- ######### Box2 ######### -->
                        <div class="home_section6_box2" :class="{ en: !isen }">
                            <div class="white_dot"></div>
                            <h1 class="box_title">2023</h1>
                            <ul class="box1_history dp_flex flex_column">
                                <li class="dp_flex">
                                    <span class="box_month box2_month" :class="{ en: !isen }">
                                        <slot name="boxmonth4">
                                            12월
                                        </slot>
                                    </span>
                                    <p class="box_month_history" :class="{ en: !isen }">
                                        <slot name="boxmonthhistory4">
                                            Pre-A 투자 유치(오비고)
                                        </slot>
                                    </p>
                                </li>
                                <li class="dp_flex">
                                    <span class="box_month box2_month" :class="{ en: !isen }">
                                        <slot name="boxmonth5">
                                            10월
                                        </slot>
                                    </span>
                                    <p class="box_month_history" :class="{ en: !isen }">
                                        <slot name="boxmonthhistory5">
                                            오비고 업무협약(MOU) 체결 <br class="home_section6_box2_br" />
                                            - 차량용 개인화 음악 서비스 개발
                                        </slot>
                                    </p>
                                </li>

                                <li class="dp_flex">
                                    <span class="box_month box2_month" :class="{ en: !isen }">
                                        <slot name="boxmonth6">
                                            9월
                                        </slot>
                                    </span>
                                    <p class="box_month_history" :class="{ en: !isen }">
                                        <slot name="boxmonthhistory6">
                                            한화비전 PoC 과제수행 계약 체결 <br class="home_section6_box2_br" />
                                            - CCTV 데이터 기반 큐레이션
                                        </slot>
                                    </p>
                                </li>
                                <li class="dp_flex">
                                    <span class="box_month box2_month" :class="{ en: !isen }">
                                        <slot name="boxmonth7">
                                            6월
                                        </slot>
                                    </span>
                                    <p class="box_month_history" :class="{ en: !isen }">
                                        <slot name="boxmonthhistory7">
                                            HMG 오픈이노베이션 Tech-Day 참여 스타트업 선정 <br />
                                            현대엔지니어링 업무협약(MOU) 체결 <br class="home_section6_box2_br" />
                                            - 음악 콘텐츠 기반 근무 환경 개선 <br />
                                            NextRise 2023, Seoul 제로원 부스 내 전시 참여
                                        </slot>
                                    </p>
                                </li>
                                <li class="dp_flex">
                                    <span class="box_month box2_month" :class="{ en: !isen }">
                                        <slot name="boxmonth8">
                                            5월
                                        </slot>
                                    </span>
                                    <p class="box_month_history" :class="{ en: !isen }">
                                        <slot name="boxmonthhistory8">
                                            소상공인 역량강화 사업 <br class="home_section6_box2_br" />
                                            - 기업가형 육성 컨설팅 공급기업 선정 <br />
                                            팁스(TIPS) 선정 <br />
                                            초기창업패키지 지원사업 선정 (서울창조경제혁신센터)
                                        </slot>
                                    </p>
                                </li>

                                <li class="dp_flex">
                                    <span class="box_month box2_month" :class="{ en: !isen }">
                                        <slot name="boxmonth9">
                                            4월
                                        </slot>
                                    </span>
                                    <p class="box_month_history" :class="{ en: !isen }">
                                        <slot name="boxmonthhistory9">
                                            벤처기업 인증
                                        </slot>
                                    </p>
                                </li>
                                <li class="dp_flex">
                                    <span class="box_month box2_month" :class="{ en: !isen }">
                                        <slot name="boxmonth10">
                                            1월
                                        </slot>
                                    </span>
                                    <p class="box_month_history" :class="{ en: !isen }">
                                        <slot name="boxmonthhistory10">
                                            Seed 투자 유치 (현대자동차그룹 제로원 2호펀드) <br />
                                            분사 및 독립 법인 설립
                                        </slot>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <!-- ######### Box3 ######### -->
                        <div class="home_section6_box3" :class="{ en: !isen }">
                            <div class="white_dot"></div>
                            <h1 class="box_title">2022</h1>
                            <ul class="box1_history dp_flex flex_column">
                                <li class="dp_flex">
                                    <span class="box_month box2_month" :class="{ en: !isen }">
                                        <slot name="boxmonth11">
                                            10월
                                        </slot>
                                    </span>
                                    <p class="box_month_history" :class="{ en: !isen }">
                                        <slot name="boxmonthhistory11">
                                            분사 승인
                                        </slot>
                                    </p>
                                </li>
                                <li class="dp_flex">
                                    <span class="box_month box2_month" :class="{ en: !isen }">
                                        <slot name="boxmonth12">
                                            8월
                                        </slot>
                                    </span>
                                    <p class="box_month_history" :class="{ en: !isen }">
                                        <slot name="boxmonthhistory12">
                                            어플레이즈 정식 서비스 오픈
                                        </slot>
                                    </p>
                                </li>

                                <li class="dp_flex">
                                    <span class="box_month box2_month" :class="{ en: !isen }">
                                        <slot name="boxmonth13">
                                            6월
                                        </slot>
                                    </span>
                                    <p class="box_month_history" :class="{ en: !isen }">
                                        <slot name="boxmonthhistory13">
                                            ㈜어플레이즈 시범 법인 설립
                                        </slot>
                                    </p>
                                </li>

                                <li class="dp_flex">
                                    <span class="box_month box2_month" :class="{ en: !isen }">
                                        <slot name="boxmonth14">
                                            5월
                                        </slot>
                                    </span>
                                    <p class="box_month_history" :class="{ en: !isen }">
                                        <slot name="boxmonthhistory14">
                                            베타 서비스 런칭
                                        </slot>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <!-- ######### Box4 ######### -->
                        <div class="home_section6_box4" :class="{ en: !isen }">
                            <div class="white_dot"></div>
                            <h1 class="box_title">2021</h1>
                            <ul class="box1_history dp_flex flex_column">
                                <li class="dp_flex">
                                    <span class="box_month box2_month" :class="{ en: !isen }">
                                        <slot name="boxmonth15">
                                            11월
                                        </slot>
                                    </span>
                                    <p class="box_month_history" :class="{ en: !isen }">
                                        <slot name="boxmonthhistory15">
                                            현대자동차그룹 ZER01NE Company Builder 선발
                                        </slot>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <div v-if="isShowMore" class="w_100 time_line_btn_wrap dp_flex flex_justify_center">
                    <button class="time_line_btn dp_flex align_center" @click="isShowMore = false">
                        <slot name="closebtn">
                            접기
                        </slot>

                        <img class="time_line_btn_arrow" src="/media/img/curation/arrow_forward_white.png" alt="접기" />
                    </button>
                </div>
            </div>
            <img class="section6_earth" src="/media/images/home/section6_earth.png" alt="section6_earth.png" />
            <div class="time_line_dim_wrap" v-if="!isShowMore">
                <button class="time_line_btn more dp_flex align_center" @click="isShowMore = true">
                    <slot name="morebtn">
                        더보기
                    </slot>

                    <img class="time_line_btn_arrow more" src="/media/img/curation/arrow_forward_white.png" alt="접기" />
                </button>
                <div class="time_line_dim"></div>
                <!-- <img class="time_line_dim" src="/media/images/home/section6_dim.png" alt="time_line_dim" /> -->
            </div>
        </div>

        <h2
            id="homeSection6News"
            class="news_contents_title wow animate__animated animate__aplayzFadeInUp "
            data-wow-offset="15"
            v-if="isen"
        >
            뉴스
        </h2>
        <ul
            id="newsContents"
            class="news_contents flex_space_between wow animate__animated animate__aplayzFadeInUp "
            data-wow-offset="20"
            v-if="isen"
        >
            <li
                class="news_contents_item flex_column cursor"
                @click="openNews('https://www.hankyung.com/article/202407154201O')"
            >
                <img class="news_contents_img" src="/media/images/home/news1.png" alt="news1" />
                <h4 class="news_contents_text">
                    넥스트유니콘, '민관협력 오픈이노베이션 지원사업 수요기반형' 상반기 20개 과제 최종 선정
                </h4>
            </li>
            <li
                class="news_contents_item middle flex_column cursor"
                @click="openNews('https://www.widedaily.com/news/articleView.html?idxno=239175')"
            >
                <img class="news_contents_img" src="/media/images/home/news2.png" alt="news2" />
                <h4 class="news_contents_text">현대건설, 혁신 스타트업 15개사 선정…미래 건설기술 검증 협업</h4>
            </li>
            <li class="news_contents_item flex_column cursor last" @click="openNews('https://www.mk.co.kr/news/it/11003945')">
                <img class="news_contents_img" src="/media/images/home/news3.png" alt="news2" />
                <h4 class="news_contents_text">AI 뮤직 어플레이즈, 앱 전면 리뉴얼 “사용자 편의성 ‘쑥’”</h4>
            </li>
        </ul>
        <div
            class="home_section6_info_wrap align_center flex_justify_center wow animate__animated animate__aplayzFadeInUp "
            data-wow-offset="100"
        >
            <div class="home_section6_info_wrap2">
                <h1 class="home_section6_info_title">
                    <slot name="section6infotitle">
                        어플레이즈로 공간의 가치를 높여보세요.
                    </slot>
                </h1>
                <ul class="home_section6_info_btn_group dp_flex flex_justify_center">
                    <li>
                        <button class="home_section6_info_btn align_center" @click="$emit('open-inquiry')">
                            <slot name="section6infobtn">
                                문의하기
                            </slot>

                            <img class="home_section6_info_arrow" src="/media/images/home/inquiry_arrow.png" alt="inquiry_arrow" />
                        </button>
                    </li>
                </ul>
            </div>

            <img src="/media/images/home/section6_info_bg.png" alt="section6_info_bg" class="home_section6_info_bg" />
        </div>
    </div>
</template>
<script>
import { WOW } from 'wowjs';
export default {
  name: 'HomeSection6',
  emits: ['open-inquiry'],
  props: {
    isen: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      isShowMore: false,
      wow: null
    };
  },

  mounted () {
    this.wow = new WOW({ live: false });
    this.wow.init();
    this.wow.sync();
  },

  methods: {
    openNews (url) {
      window.open(url, '_blank');
    }
  }
};
</script>
<style scoped src="@/assets/css/home/homesection6.css"></style>
